<template>
    <div>
        <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal"}'>
            <thead>
                <tr :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400"}}'>
                    <td
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Sondas
                    </td>

                    <td
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Sonda Vesical
                    </td>

                    <td
                        :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    >
                        Catéteres
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td :style='estilos.tablaBorde'>
                        <div
                            v-if="datos && datos.sonda > 0"
                        >
                            <b>Tipo:</b> {{tipoSonda[datos.sonda].text}}<br/>
                            <b>Calibre:</b> {{datos.sondaCalibre}}<br/>
                            <b>Instala:</b> {{datos.sondaInstalacion}}<br/>
                            <b>Retira:</b> {{datos.sondaRetiro}}<br/>
                            <b>Observaciones:</b><br/>{{datos.sondaObservaciones}}<br/>
                        </div>
                        <div
                            v-if="datos && datos.sonda == 0"
                        >
                            No se requiere
                        </div>
                    </td>

                    <td :style='estilos.tablaBorde'>
                        <div
                            v-if="datos && datos.vesical"
                        >
                            <b>Calibre:</b> {{datos.vesicalCalibre}}<br/>
                            <b>Instala:</b> {{datos.vesicalInstalacion}}<br/>
                            <b>Retira:</b> {{datos.vesicalRetiro}}<br/>
                            <b>Observaciones:</b><br/>{{datos.vesicalObservaciones}}<br/>
                        </div>
                        <div
                            v-if="datos && !datos.vesical"
                        >
                            No se requiere
                        </div>
                    </td>
                    <td :style='estilos.tablaBorde'>

                        <div
                            v-if="datos && datos.accesosVasc != 'No se requiere'"
                        >
                            <b>Tipo y calibre</b><br/>{{datos.vascuTipoCalibre}}<br/>
                            <b>Intentos:</b> {{datos.vascuIntentos}}<br/>
                            <b>Insercción:</b> {{datos.vascuSitio}}<br/>
                            <b>Insta:</b> {{datos.vascuFechaInstala}}<br/>
                            <b>Otros accesos:</b><br/>{{datos.otrosAccesosVasculares}}<br/>
                        </div>
                        <div
                            v-if="datos && datos.accesosVasc == 'No se requiere'"
                        >
                            No se requiere
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],
    data:()=>({
        tipoSonda:{
            0: {text: 'No requiere'},
            1: {text: 'Nasogasrtica'},
            2: {text: 'Orogástrica'},
            3: {text: 'Gastrostomia'},
        },
    }),
}
</script>

<style>

</style>